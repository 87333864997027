import React from "react";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import DeviceArray from "./DeviceArray";

interface DeviceListRouteProps {}

const DeviceListRoute: React.FC<DeviceListRouteProps> = () => {
  const { property } = useProperty();

  return (
    <>
      <Heading>Devices</Heading>
      <Text className="mb-8">
        This list contains the devices your users can use.
      </Text>
      <DeviceArray
        deviceListParams={{
          propertyId: property?.id,
        }}
      />
    </>
  );
};

export default DeviceListRoute;
