import React from "react";
import { Heading, Subheading } from "./heading";
import { Strong, TextLink } from "./text";
import ReactMarkdown from "react-markdown";
import { Divider } from "./divider";

interface MarkdownProps {
  markdown: string | null | undefined;
}

const Markdown: React.FC<MarkdownProps> = ({ markdown }) => {
  if (markdown === null || markdown === undefined) {
    return null;
  }

  return (
    <>
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <>
              <Heading {...props} className="mt-8" />
              <Divider className="mb-8" />
            </>
          ),
          h2: ({ node, ...props }) => (
            <>
              <Subheading {...props} className="mt-8" />
              <Divider className="mb-2" />
            </>
          ),
          h3: ({ node, ...props }) => <Subheading level={3} {...props} />,
          strong: ({ node, ...props }) => <Strong {...props} />,

          // Unordered list
          ul: ({ node, ...props }) => (
            <ul
              style={{ paddingLeft: "20px", listStyleType: "disc" }}
              {...props}
            />
          ),

          // Ordered list
          ol: ({ node, ...props }) => (
            <ol
              style={{ paddingLeft: "20px", listStyleType: "decimal" }}
              {...props}
            />
          ),

          // List item
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "8px" }} {...props} />
          ),

          // Link handling with custom TextLink component
          a: ({ href, children, ...props }) => (
            <TextLink href={href ?? ""} {...props}>
              {children}
            </TextLink>
          ),

          // Horizontal rule support using Divider component
          hr: () => <Divider />,
        }}
      >
        {markdown}
      </ReactMarkdown>
    </>
  );
};

export default Markdown;
