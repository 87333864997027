import React, { useCallback, useEffect, useMemo, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import { juhuu } from "../../juhuuClass";
import { Text } from "../../components/text";
import BadgeArticleStatus from "./BadgeStatusArticle";
import {
  Pagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from "../../components/pagination";

interface ArticleArrayProps {
  articleListParams?: JUHUU.Article.List.Params;
  articleIdArray?: string[];
}

const ArticleArray: React.FC<ArticleArrayProps> = ({
  articleListParams,
  articleIdArray,
}) => {
  const [articleArray, setArticleArray] = useState<JUHUU.Article.Object[]>([]);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const stepSize = 10;
  const pageGapThreshold = 5;

  const maxPages = useMemo(() => {
    return Math.ceil(count / stepSize);
  }, [count, stepSize]);

  const handleRefresh = useCallback(async () => {
    if (articleListParams !== undefined) {
      const query = await juhuu.articles.list(articleListParams, {
        limit: stepSize,
        skip: (page - 1) * stepSize,
      });

      if (query.ok === false) {
        return;
      }

      setArticleArray(query.data.articleArray);
      setCount(query.data.count);
    } else if (articleIdArray !== undefined) {
      console.log("articleIdArray", articleIdArray);
      // fetch articles by id
      const queryPromiseArray: Promise<
        JUHUU.HttpResponse<JUHUU.Article.Retrieve.Response>
      >[] = [];

      articleIdArray.forEach(async (articleId) => {
        queryPromiseArray.push(
          juhuu.articles.retrieve({
            articleId: articleId,
          })
        );
      });

      setCount(articleIdArray.length);

      const queryArray = await Promise.all(queryPromiseArray);

      const tempArticleArray: JUHUU.Article.Object[] = [];

      queryArray.forEach((query) => {
        if (query.ok === true) {
          tempArticleArray.push(query.data.article);
        }
      });

      setArticleArray(tempArticleArray);
    }
  }, [articleListParams, articleIdArray, page, stepSize]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const renderPagination = () => {
    const paginationItems = [];

    // Less than or equal to pageGapThreshold pages, show all pages
    if (maxPages <= pageGapThreshold) {
      for (let index = 0; index < maxPages; index++) {
        paginationItems.push(
          <PaginationPage
            key={index}
            onClick={() => setPage(index + 1)}
            current={index + 1 === page}
          >
            {index + 1}
          </PaginationPage>
        );
      }
    } else {
      for (
        let index = 0;
        index < Math.min(pageGapThreshold - 1, maxPages);
        index++
      ) {
        paginationItems.push(
          <PaginationPage
            key={index}
            onClick={() => setPage(index + 1)}
            current={index + 1 === page}
          >
            {index + 1}
          </PaginationPage>
        );
      }

      if (maxPages > pageGapThreshold) {
        paginationItems.push(<PaginationGap key="gap" />);
        paginationItems.push(
          <PaginationPage
            key={maxPages - 1}
            onClick={() => setPage(maxPages)}
            current={maxPages === page}
          >
            {maxPages}
          </PaginationPage>
        );
      }
    }

    return paginationItems;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {articleArray.map((article) => {
            return (
              <TableRow
                key={article.id}
                href={
                  "/properties/" +
                  article.propertyId +
                  "/articles/" +
                  article.id
                }
              >
                <TableCell>
                  <Text>{article.title.en}</Text>
                </TableCell>
                <TableCell>
                  <BadgeArticleStatus status={article.status} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {maxPages > 1 && (
        <Pagination className="mt-8">
          <PaginationPrevious
            onClick={() => setPage(Math.max(0, page - 1))}
            disabled={page === 1}
          />
          <PaginationList>{renderPagination()}</PaginationList>
          <PaginationNext
            disabled={page === maxPages}
            onClick={() => {
              console.log("page", page);
              setPage(Math.min(maxPages, page + 1));
            }}
          />
        </Pagination>
      )}
    </>
  );
};

export default ArticleArray;
