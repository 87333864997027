import { JUHUU } from "@juhuu/sdk-ts";
import { Badge } from "../../components/badge";

interface BadgeArticleStatusProps {
  status: JUHUU.Article.Object["status"] | null | undefined;
}

const BadgeArticleStatus: React.FC<BadgeArticleStatusProps> = ({ status }) => {
  switch (status) {
    case "published": {
      return <Badge color="lime">Published</Badge>;
    }

    case "draft": {
      return <Badge color="zinc">Draft</Badge>;
    }

    default: {
      return null;
    }
  }
};

export default BadgeArticleStatus;
