import { useCallback, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "../../components/table";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { Badge } from "../../components/badge";
import { Text } from "../../components/text";
import { Button } from "../../components/button";
import { useUser } from "../../context/UserContext";
import { Avatar } from "../../components/avatar";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../../components/dialog";
import { Field, Label } from "../../components/fieldset";
import { Input } from "../../components/input";

interface PropertyListRouteProps {}

const PropertyListRoute: React.FC<PropertyListRouteProps> = () => {
  const [propertyArray, setPropertyArray] = useState<JUHUU.Property.Object[]>(
    []
  );

  const [createDialog, setCreateDialog] = useState(false);
  const [propertyName, setPropertyName] = useState("");
  const [propertyCreateLoading, setPropertyCreateLoading] = useState(false);
  const [enableCreateButton, setEnableCreateButton] = useState(false);

  const { user } = useUser();
  const handleRefresh = useCallback(async () => {
    if (user === null) {
      return;
    }

    const query = await juhuu.properties.list({});

    if (query.ok === false) {
      return;
    }

    // properties that the users owns are first
    const sortedPropertyArray = query.data.sort((property) => {
      if (
        property.ownerUserIdArray !== undefined &&
        property.ownerUserIdArray.includes(user?.id) === true
      ) {
        return -1;
      } else {
        return 1;
      }
    });

    setPropertyArray(sortedPropertyArray);
  }, [user]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const handleCreateProperty = async () => {
    if (propertyName.length < 4 || propertyName.length > 100) {
      return;
    }

    console.log("Creating property");
    setPropertyCreateLoading(true);
    const query = await juhuu.properties.create({
      name: propertyName,
      type: "internal",
      userId: user?.id,
    });
    setPropertyCreateLoading(false);

    if (query.ok === false) {
      return;
    }

    setCreateDialog(false);
    handleRefresh();
  };

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4">
        <Heading>Registered operators at JUHUU</Heading>

        <div className="flex gap-4">
          <Button onClick={() => setCreateDialog(true)}>Create</Button>
        </div>
      </div>
      <Text className="pb-4">
        Become an operator at JUHUU now or select your company from the list.
      </Text>
      <Table>
        <TableBody>
          {propertyArray.map((property) => {
            const userIsOwner = property.ownerUserIdArray?.includes(
              user?.id ?? "abc"
            );
            return (
              <TableRow
                key={property.id}
                href={userIsOwner ? "/properties/" + property.id : undefined}
              >
                <TableCell className="font-medium flex flex-row justify-start items-center gap-4">
                  <Avatar
                    src={property.iconLight}
                    initials={
                      property.iconLight === null
                        ? property.name.slice(0, 2)
                        : undefined
                    }
                    className="size-12"
                  />
                  <Text>{property.name}</Text>
                </TableCell>
                <TableCell>
                  {userIsOwner === true ? (
                    <Badge color="lime">Owned</Badge>
                  ) : (
                    <Badge color="zinc">Not owned</Badge>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Dialog open={createDialog} onClose={setCreateDialog}>
        <DialogTitle>Become an operator or retailer at JUHUU now!</DialogTitle>
        <DialogDescription>
          Fill in the name of your company to get started.
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>Name</Label>
            <Input
              name="name"
              disabled={propertyCreateLoading}
              placeholder="Business Ltd."
              onChange={(event) => {
                if (
                  event.target.value.length < 4 ||
                  event.target.value.length > 100
                ) {
                  setEnableCreateButton(false);
                } else {
                  setEnableCreateButton(true);
                }
                setPropertyName(event.target.value);
              }}
            />
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setCreateDialog(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => handleCreateProperty()}
            disabled={propertyCreateLoading || enableCreateButton === false}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PropertyListRoute;
