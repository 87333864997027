import { OfferTime as JuhuuOfferTimeType } from "@juhuu/sdk-ts";
import React from "react";
import { Strong, Text } from "../components/text";

interface OfferTimeProps {
  offerTime: JuhuuOfferTimeType | null | undefined;
}

const OfferTime: React.FC<OfferTimeProps> = ({ offerTime }) => {
  // Helper function to convert minutes to HH:mm format
  const formatTime = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  // Helper function to render opening times for each day
  const renderDay = (
    day: string,
    times: { startMinutes: number; endMinutes: number }[]
  ) => {
    if (!times || times.length === 0) {
      return null;
    }
    return (
      <div className="flex flex-row justify-between items-start">
        <Strong>{day}</Strong>
        <div className="flex flex-col justify-start items-start">
          {times.map((time, index) => (
            <Text key={index}>
              {formatTime(time.startMinutes)} - {formatTime(time.endMinutes)}
            </Text>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {offerTime ? (
        <>
          {renderDay("Monday", offerTime.mon)}
          {renderDay("Tuesday", offerTime.tue)}
          {renderDay("Wednesday", offerTime.wed)}
          {renderDay("Thursday", offerTime.thu)}
          {renderDay("Friday", offerTime.fri)}
          {renderDay("Saturday", offerTime.sat)}
          {renderDay("Sunday", offerTime.sun)}
        </>
      ) : (
        <Text>No offerTime</Text>
      )}
    </div>
  );
};

export default OfferTime;
