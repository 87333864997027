import { JUHUU } from "@juhuu/sdk-ts";
import { Badge } from "../../components/badge";

interface BadgConnectorStatusProps {
  status: JUHUU.Connector.Object["status"] | null | undefined;
}

const BadgeConnectorStatus: React.FC<BadgConnectorStatusProps> = ({
  status,
}) => {
  switch (status) {
    case "offline": {
      return <Badge color="zinc">Offline</Badge>;
    }

    case "online": {
      return <Badge color="lime">Online</Badge>;
    }

    default: {
      return null;
    }
  }
};

export default BadgeConnectorStatus;
