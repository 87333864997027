import React, { useMemo } from "react";

interface DurationProps {
  seconds: number | null;
  display: "duration" | "clock";
}

const Duration: React.FC<DurationProps> = ({
  seconds: externalSeconds,
  display,
}) => {
  const text: string | null = useMemo(() => {
    if (externalSeconds === null) {
      return null;
    }

    // Define time units in seconds
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2592000; // Approximate (30 days)
    const secondsInYear = 31536000; // Approximate (365 days)

    const years = Math.floor(externalSeconds / secondsInYear);
    const months = Math.floor(
      (externalSeconds % secondsInYear) / secondsInMonth
    );
    const weeks = Math.floor(
      (externalSeconds % secondsInMonth) / secondsInWeek
    );
    const days = Math.floor((externalSeconds % secondsInWeek) / secondsInDay);
    const hours = Math.floor((externalSeconds % secondsInDay) / secondsInHour);
    const minutes = Math.floor(
      (externalSeconds % secondsInHour) / secondsInMinute
    );
    const seconds = Math.floor(externalSeconds % secondsInMinute);

    switch (display) {
      case "duration":
        if (years > 0) {
          return `${years} yr${years > 1 ? "s" : ""} ${
            months > 0 ? months + " mo" : ""
          }`;
        } else if (months > 0) {
          return `${months} mo${months > 1 ? "s" : ""} ${
            weeks > 0 ? weeks + " wk" : ""
          }`;
        } else if (weeks > 0) {
          return `${weeks} wk${weeks > 1 ? "s" : ""} ${
            days > 0 ? days + " day" : ""
          }`;
        } else if (days > 0) {
          return `${days} day${days > 1 ? "s" : ""} ${
            hours > 0 ? hours + " h" : ""
          }`;
        } else if (hours > 0) {
          return `${hours} h ${minutes > 0 ? minutes + " min" : ""}`;
        } else if (minutes > 0) {
          return `${minutes} min ${seconds > 0 ? seconds + " s" : ""}`;
        } else {
          return `${seconds} s`;
        }

      case "clock":
        // Not yet implemented, but you can implement it similarly.
        return "not implemented yet";

      default:
        return null;
    }
  }, [externalSeconds, display]);

  return <>{text}</>;
};

export default Duration;
