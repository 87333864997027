import { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Badge } from "../../components/badge";
import { Text } from "../../components/text";
import { Button } from "../../components/button";
import Timestamp from "../../formatters/Timestamp";

interface ConnectorListRouteProps {}

const ConnectorListRoute: React.FC<ConnectorListRouteProps> = () => {
  const [connectorArray, setConnectorArray] = useState<
    JUHUU.Connector.Object[]
  >([]);

  const { property } = useProperty();
  const handleRefresh = useCallback(async () => {
    if (property?.id === undefined) {
      return;
    }

    const query = await juhuu.connectors.list({
      propertyId: property?.id,
    });

    if (query.ok === false) {
      return;
    }

    setConnectorArray(query.data);
  }, [property]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <Heading>Connectors</Heading>
      <Text className="mb-8">
        A connector representes the connection between a real-world device and
        JUHUU.
      </Text>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {connectorArray.map((connector) => {
            return (
              <TableRow key={connector.id} href={"./" + connector.id}>
                <TableCell>{connector.name} </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default ConnectorListRoute;
