import { JUHUU } from "@juhuu/sdk-ts";

interface SessionTypeProps {
  type: JUHUU.Session.Object["type"] | null | undefined;
}

const SessionType: React.FC<SessionTypeProps> = ({ type }) => {
  switch (type) {
    case "rent": {
      return <>Rental</>;
    }

    case "reservation": {
      return <>Reservation</>;
    }

    default: {
      return null;
    }
  }
};

export default SessionType;
