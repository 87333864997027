import { JUHUU } from "@juhuu/sdk-ts";
import React from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "./dropdown";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { Input } from "./input";

interface ParameterInputProps {
  parameter: JUHUU.DeviceTemplate.Object["parameterArray"][0];
  onChange: (
    parameter: JUHUU.DeviceTemplate.Object["parameterArray"][0],
    value: string
  ) => void;
}

const ParameterInput: React.FC<ParameterInputProps> = ({
  parameter,
  onChange,
}) => {
  const renderInput = () => {
    switch (parameter.type) {
      case "number":
        return (
          <Input
            type="number"
            placeholder={parameter.current.toString()}
            defaultValue={parameter.current}
            onChange={(event) => onChange(parameter, event.target.value)}
          />
        );
      case "boolean":
        return (
          <Dropdown>
            <DropdownButton outline>
              {parameter.current.toString()}
              <ChevronDownIcon />
            </DropdownButton>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  onChange(parameter, "true");
                }}
              >
                True
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  onChange(parameter, "false");
                }}
              >
                False
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        );
      case "enum":
        return (
          <Dropdown>
            <DropdownButton outline>
              {parameter.current}
              <ChevronDownIcon />
            </DropdownButton>
            <DropdownMenu>
              {parameter.enumArray.map((option) => (
                <DropdownItem
                  key={option}
                  onClick={() => {
                    onChange(parameter, option);
                  }}
                >
                  {option}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        );
      case "string":
      default:
        return (
          <Input
            type="text"
            placeholder={parameter.current.toString()}
            defaultValue={parameter.current}
            onChange={(event) => onChange(parameter, event.target.value)}
          />
        );
    }
  };

  return <>{renderInput()}</>;
};

export default ParameterInput;
