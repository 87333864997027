import React, { useCallback, useEffect, useMemo, useState } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { juhuu } from "../../juhuuClass";
import { Text } from "../../components/text";
import {
  Pagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from "../../components/pagination";
import Timestamp from "../../formatters/Timestamp";

interface ChatArrayProps {
  chatListParams?: JUHUU.Chat.List.Params;
  chatIdArray?: string[];
  onSelectedChat?: (chat: JUHUU.Chat.Object) => void;
}

const ChatArray: React.FC<ChatArrayProps> = ({
  chatListParams,
  chatIdArray,
  onSelectedChat,
}) => {
  const [chatArray, setChatArray] = useState<JUHUU.Chat.Object[]>([]);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const stepSize = 9;
  const pageGapThreshold = 5;

  const maxPages = useMemo(() => {
    return Math.ceil(count / stepSize);
  }, [count, stepSize]);

  const handleRefresh = useCallback(async () => {
    if (chatListParams !== undefined) {
      const query = await juhuu.chats.list(chatListParams, {
        limit: stepSize,
        skip: (page - 1) * stepSize,
      });

      if (query.ok === false) {
        return;
      }

      setChatArray(query.data.chatArray);
      setCount(query.data.count);
    } else if (chatIdArray !== undefined) {
      console.log("chatIdArray", chatIdArray);
      // fetch chats by id
      const queryPromiseArray: Promise<
        JUHUU.HttpResponse<JUHUU.Chat.Retrieve.Response>
      >[] = [];

      chatIdArray.forEach(async (chatId) => {
        queryPromiseArray.push(
          juhuu.chats.retrieve({
            chatId: chatId,
          })
        );
      });

      setCount(chatIdArray.length);

      const queryArray = await Promise.all(queryPromiseArray);

      const tempChatArray: JUHUU.Chat.Object[] = [];

      queryArray.forEach((query) => {
        if (query.ok === true) {
          tempChatArray.push(query.data.chat);
        }
      });

      setChatArray(tempChatArray);
    }
  }, [chatListParams, chatIdArray, page, stepSize]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const renderPagination = () => {
    const paginationItems = [];

    // Less than or equal to pageGapThreshold pages, show all pages
    if (maxPages <= pageGapThreshold) {
      for (let index = 0; index < maxPages; index++) {
        paginationItems.push(
          <PaginationPage
            key={index}
            onClick={() => setPage(index + 1)}
            current={index + 1 === page}
          >
            {index + 1}
          </PaginationPage>
        );
      }
    } else {
      for (
        let index = 0;
        index < Math.min(pageGapThreshold - 1, maxPages);
        index++
      ) {
        paginationItems.push(
          <PaginationPage
            key={index}
            onClick={() => setPage(index + 1)}
            current={index + 1 === page}
          >
            {index + 1}
          </PaginationPage>
        );
      }

      if (maxPages > pageGapThreshold) {
        paginationItems.push(<PaginationGap key="gap" />);
        paginationItems.push(
          <PaginationPage
            key={maxPages - 1}
            onClick={() => setPage(maxPages)}
            current={maxPages === page}
          >
            {maxPages}
          </PaginationPage>
        );
      }
    }

    return paginationItems;
  };

  return (
    <>
      <div className="w-full grid grid-cols-3 gap-4">
        {chatArray.map((chat) => {
          return (
            <div
              key={chat.id}
              className={
                "w-1/3 p-2 bg-zinc-100 border border-zinc-300 rounded-lg h-32 w-full cursor-pointer flex flex-col justify-between"
              }
              onClick={() => {
                if (onSelectedChat !== undefined) {
                  onSelectedChat(chat);
                }
              }}
            >
              <Text>{chat.title}</Text>
              <Timestamp timestamp={new Date(chat?.lastMessageAt ?? "")} />
            </div>
          );
        })}
      </div>
      {maxPages > 1 && (
        <Pagination className="mt-8">
          <PaginationPrevious
            onClick={() => setPage(Math.max(0, page - 1))}
            disabled={page === 1}
          />
          <PaginationList>{renderPagination()}</PaginationList>
          <PaginationNext
            disabled={page === maxPages}
            onClick={() => {
              console.log("page", page);
              setPage(Math.min(maxPages, page + 1));
            }}
          />
        </Pagination>
      )}
    </>
  );
};

export default ChatArray;
