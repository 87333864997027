import { LanguageCode } from "@juhuu/sdk-ts";
import React, { useMemo } from "react";

interface FormatLanguageCodeProps {
  languageCode: LanguageCode | null | undefined;
}

const FormatLanguageCode: React.FC<FormatLanguageCodeProps> = ({
  languageCode,
}) => {
  const text: string | null = useMemo(() => {
    switch (languageCode) {
      case "en":
        return "English";
      case "de":
        return "German";
      case "fr":
        return "French";
      case "nl":
        return "Dutch";

      case "it":
        return "Italian";
      case "cs":
        return "Czech";
      case "da":
        return "Danish";
      case "es":
        return "Spanish";
      case "et":
        return "Estonian";
      case "gsw":
        return "Swiss German";
      case "hr":
        return "Croatian";
      case "hu":
        return "Hungarian";
      case "no":
        return "Norwegian";
      case "pl":
        return "Polish";
      case "sv":
        return "Swedish";

      default:
        return "Unknown";
    }
  }, [languageCode]);

  return <>{text}</>;
};

export default FormatLanguageCode;
