import { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";

interface UserListRouteProps {}

const UserListRoute: React.FC<UserListRouteProps> = () => {
  const [userArray, setUserArray] = useState<JUHUU.User.Object[]>([]);

  const { property } = useProperty();
  const handleRefresh = useCallback(async () => {
    if (property?.id === undefined) {
      return;
    }
    const query = await juhuu.users.list({
      propertyId: property?.id,
    });
    if (query.ok === false) {
      return;
    }
    setUserArray(query.data);
  }, [property]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <Heading>Customers</Heading>
      <Text className="mb-8">
        This list contains everyone who ever rented, reserved or payed for one
        of the services you offer on the JUHUU platform.
      </Text>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>E-mail</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {userArray.map((user) => {
            return (
              <TableRow key={user.id} href={"./" + user.id}>
                <TableCell>{user.name}</TableCell>

                <TableCell>{user.billingEmail}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default UserListRoute;
