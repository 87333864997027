import { useCallback, useEffect, useState } from "react";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import BadgeStatusPayment from "./BadgeStatusPayment";
import Timestamp from "../../formatters/Timestamp";
import PaymentMethod from "../../formatters/PaymentMethod";
import Amount from "../../formatters/Amount";
import { Divider } from "../../components/divider";
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from "../../components/description-list";
import Timeline from "./Timeline";

interface PaymentRouteProps {}

const PaymentRoute: React.FC<PaymentRouteProps> = () => {
  const [payment, setPayment] = useState<JUHUU.Payment.Object | null>(null);
  const { paymentId } = useParams<{ paymentId: string }>();

  const handleRefresh = useCallback(async () => {
    if (paymentId === undefined) {
      return;
    }

    const query = await juhuu.payments.retrieve({
      paymentId: paymentId,
    });

    if (query.ok === false) {
      return;
    }

    setPayment(query.data.payment);
  }, [paymentId]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
        <div className="flex gap-4 items-center">
          <Heading>Payment</Heading>
          <BadgeStatusPayment status={payment?.status} />
        </div>

        <div className="flex items-center gap-4">
          <div className="flex items-center gap-1">
            <Text>{paymentId}</Text>
          </div>

          {/* <Button
            href={
              "/properties/" + property?.id + "/sessions/" + sessionId + "/edit"
            }
          >
            Edit
          </Button> */}
        </div>
      </div>
      <Divider />
      <div className="flex justify-start flex-row items-center py-4">
        <div className="flex justfy-start flex-col items-start pr-8">
          <Text>Payment method</Text>
          <Text>
            <PaymentMethod paymentMethod={payment?.paymentMethod} />
          </Text>
        </div>
        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>Created</Text>
          <Text>
            <Timestamp timestamp={payment?.createdAt} />
          </Text>
        </div>
        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>User ID</Text>
          <Text>{payment?.userId}</Text>
        </div>
      </div>
      <Timeline payment={payment} />
      <Subheading className="mt-8">Details</Subheading>
      <Divider />
      <DescriptionList>
        <DescriptionTerm>Amount authorized</DescriptionTerm>
        <DescriptionDetails>
          <Amount
            amount={payment?.amountAuthorized}
            currencyCode={payment?.currencyCode}
          />
        </DescriptionDetails>

        <DescriptionTerm>Amount authorized without service fee</DescriptionTerm>
        <DescriptionDetails>
          <Amount
            amount={payment?.amountAuthorizedWithoutServiceFee}
            currencyCode={payment?.currencyCode}
          />
        </DescriptionDetails>

        <DescriptionTerm>Amount final</DescriptionTerm>
        <DescriptionDetails>
          <Amount
            amount={payment?.amountFinal}
            currencyCode={payment?.currencyCode}
          />
        </DescriptionDetails>

        <DescriptionTerm>Amount captured</DescriptionTerm>
        <DescriptionDetails>
          <Amount
            amount={payment?.amountCaptured}
            currencyCode={payment?.currencyCode}
          />
        </DescriptionDetails>

        <DescriptionTerm>Amount captured net</DescriptionTerm>
        <DescriptionDetails>
          <Amount
            amount={payment?.amountCapturedNet}
            currencyCode={payment?.currencyCode}
          />
        </DescriptionDetails>

        <DescriptionTerm>Amount to payout</DescriptionTerm>
        <DescriptionDetails>
          <Amount
            amount={payment?.amountToPayout}
            currencyCode={payment?.currencyCode}
          />
        </DescriptionDetails>
        <DescriptionTerm>Service fee</DescriptionTerm>
        <DescriptionDetails>
          <Amount
            amount={payment?.serviceFee}
            currencyCode={payment?.currencyCode}
          />
        </DescriptionDetails>
        <DescriptionTerm>Tax {payment?.salesTaxPercentage}%</DescriptionTerm>
        <DescriptionDetails>
          <Amount
            amount={payment?.taxAmount}
            currencyCode={payment?.currencyCode}
          />
        </DescriptionDetails>
      </DescriptionList>
    </>
  );
};

export default PaymentRoute;
/*
amountAuthorized: number; // amount of the payment that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the payment that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
