import { Address as JuhuuAddressType } from "@juhuu/sdk-ts";
import React, { useMemo } from "react";

interface AddressProps {
  address: JuhuuAddressType | null | undefined;
}

const Address: React.FC<AddressProps> = ({ address }) => {
  const text: string | null = useMemo(() => {
    if (address === null || address === undefined) {
      return "No address";
    }

    return address.line1 + ", " + address.postalCode + " " + address.city;
  }, [address]);

  return <>{text}</>;
};

export default Address;
