import { useCallback, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "../components/table";
import { juhuu } from "../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../components/heading";
import { useUser } from "../context/UserContext";
import { Avatar } from "../components/avatar";
import { useProperty } from "../context/PropertyContext";
import { Strong, Text, TextLink } from "../components/text";

interface ErrorRouteProps {}

const ErrorRoute: React.FC<ErrorRouteProps> = () => {
  return (
    <>
      <Heading>This page could not be found.</Heading>
    </>
  );
};

export default ErrorRoute;
