import { useCallback, useEffect, useState } from "react";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { useParams } from "react-router-dom";
import { Text, TextLink } from "../../components/text";
import { Divider } from "../../components/divider";
import Payment from "../payments/Payment";
import BadgeSimStatus from "./BadgeSimStatus";
import Timestamp from "../../formatters/Timestamp";
import { Button } from "../../components/button";

interface SimRouteProps {}

const SimRoute: React.FC<SimRouteProps> = () => {
  const [sim, setSim] = useState<JUHUU.Sim.Object | null>(null);
  const { simId } = useParams<{ simId: string }>();

  const handleRefreshFromProvider = useCallback(async () => {
    if (simId === undefined) {
      return;
    }

    const query = await juhuu.sims.updateFromProvider({
      simId: simId,
    });

    if (query.ok === false) {
      return;
    }

    setSim(query.data.sim);
  }, [simId]);

  const handleRefresh = useCallback(async () => {
    if (simId === undefined) {
      return;
    }

    const query = await juhuu.sims.retrieve({
      simId: simId,
    });

    if (query.ok === false) {
      return;
    }

    setSim(query.data.sim);
  }, [simId]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
        <div className="flex gap-4 items-center">
          <Heading>Sim</Heading>
          <BadgeSimStatus status={sim?.status} />
        </div>

        <div className="flex items-center gap-4">
          <div className="flex items-center gap-1">
            <Text>{simId}</Text>
          </div>

          <Button onClick={handleRefreshFromProvider}>
            Refresh from provider
          </Button>
        </div>
      </div>
      <Divider />
      <div className="flex justify-start flex-row items-center py-4">
        <div className="flex justfy-start flex-col items-start pr-8">
          <Text>Provider</Text>
          <TextLink
            href={`https://portal.1nce.com/portal/customer/sims?updateSettings=true&currentSearch=&currentPage=1&pageSize=10&status=&quotaStatus=&smsQquotaStatus=&sortBy=iccid&sortOrder=ASC&tariffDetail=&extendable=&all=${sim?.iccid}&searchTerm=all`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {sim?.provider}
          </TextLink>
        </div>
        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>Data volume current</Text>
          <Text>{sim?.dataQuotaCurrent ?? "---"} MB</Text>
        </div>
        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>Data volume max.</Text>
          <Text>{sim?.dataQuotaMax ?? "---"} MB</Text>
        </div>
        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>Data volume threshold</Text>
          <Text>{sim?.dataQuotaThresholdPercentage ?? "---"} %</Text>
        </div>
      </div>
    </>
  );
};

export default SimRoute;
