export default function parseBoolean(
  value: string | null | undefined | number | boolean
): boolean {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else {
    throw new Error("Invalid boolean string");
  }
}
