import { JUHUU } from "@juhuu/sdk-ts";

interface ConnectorTypeProps {
  type: JUHUU.Connector.Object["type"] | null | undefined;
}

const ConnectorType: React.FC<ConnectorTypeProps> = ({ type }) => {
  switch (type) {
    case "mqtt": {
      return <>MQTT</>;
    }

    default: {
      return null;
    }
  }
};

export default ConnectorType;
