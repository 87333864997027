import { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { Text } from "../../components/text";
import Amount from "../../formatters/Amount";
import Duration from "../../formatters/Duration";

interface TariffListRouteProps {}

const TariffListRoute: React.FC<TariffListRouteProps> = () => {
  const [tariffArray, setTariffArray] = useState<JUHUU.Tariff.Object[]>([]);

  const { property } = useProperty();
  const handleRefresh = useCallback(async () => {
    if (property?.id === undefined) {
      return;
    }

    const query = await juhuu.tariffs.list({
      propertyId: property?.id,
    });

    if (query.ok === false) {
      return;
    }

    setTariffArray(query.data);
  }, [property]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <Heading>Tariffs</Heading>

      <Text className="mb-8">
        Tariffs allow you to set prices for the services you offer.
      </Text>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Duration</TableHeader>
            <TableHeader>Maximum amount</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {tariffArray.map((tariff) => {
            return (
              <TableRow key={tariff.id} href={"./" + tariff.id}>
                <TableCell>{tariff.name.en} </TableCell>
                <TableCell>
                  <Duration seconds={tariff.duration} display="duration" />
                </TableCell>
                <TableCell>
                  <Amount
                    amount={juhuu.tariffs.calculateMaximumAmount(tariff)}
                    currencyCode={tariff.currencyCode}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default TariffListRoute;
