import { useCallback, useEffect, useState } from "react";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Divider } from "../../components/divider";
import BadgeDeviceStatus from "./BadgeStatusDevice";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import Timestamp from "../../formatters/Timestamp";
import { Button } from "../../components/button";
import { Field } from "../../components/fieldset";
import ParameterInput from "../../components/parameter-input";
import parseBoolean from "../../formatters/parseBoolean";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from "../../components/description-list";
import Device from "./Device";

interface DeviceRouteProps {}

const DeviceRoute: React.FC<DeviceRouteProps> = () => {
  const [device, setDevice] = useState<JUHUU.Device.Object | null>(null);
  const [deviceTemplate, setDeviceTemplate] =
    useState<JUHUU.DeviceTemplate.Object | null>(null);

  const { deviceId } = useParams<{ deviceId: string }>();

  const { property } = useProperty();
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);

  const handleRefresh = useCallback(async () => {
    if (property === null) {
      return;
    }

    if (deviceId === undefined) {
      return;
    }

    const deviceQuery = await juhuu.devices.retrieve(
      {
        deviceId: deviceId,
      },
      {
        expand: ["deviceTemplate"],
      }
    );

    if (deviceQuery.ok === false) {
      return;
    }

    setDevice(deviceQuery.data.device);

    if (deviceQuery.data.deviceTemplate !== undefined) {
      setDeviceTemplate(deviceQuery.data.deviceTemplate);
    }
  }, [deviceId, property]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const handleParameterUpdate = useCallback(
    async (parameter: JUHUU.Device.Object["parameterArray"][0]) => {
      if (deviceId === undefined) {
        return;
      }

      await juhuu.devices.parameterUpdate({
        deviceId: deviceId,
        parameterName: parameter.name,
        value: parameter.current,
      });

      await handleRefresh();
    },
    [deviceId, handleRefresh]
  );

  const handleParameterOnChange = useCallback(
    (
      parameter: JUHUU.Device.Object["parameterArray"][0],
      value: boolean | string | number
    ) => {
      if (device === null) {
        return;
      }

      console.log("parameter", parameter);

      const newParameterArray = device?.parameterArray.map((p) => {
        if (p.name === parameter.name) {
          switch (p.type) {
            case "number":
              return {
                ...p,
                current: parseFloat(value.toString()),
              };
            case "boolean":
              return {
                ...p,
                current: parseBoolean(value),
              };
            case "enum":
              return {
                ...p,
                current: value.toString(),
              };

            case "string":
              return {
                ...p,
                current: value.toString(),
              };
            default: {
              return p;
            }
          }
        }

        return p;
      });

      if (newParameterArray === undefined) {
        return;
      }

      console.log("newParameterArray", newParameterArray);

      setDevice({
        ...device,
        parameterArray: newParameterArray,
      });
    },
    [setDevice, device]
  );

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
        <div className="flex gap-4 items-center">
          <Heading>Device</Heading>
          <BadgeDeviceStatus status={device?.status} />
        </div>

        <div className="flex items-center gap-4">
          <div className="flex items-center gap-4">
            <Text>{deviceId}</Text>
            <Device
              display="callToActionButton"
              deviceTemplate={deviceTemplate}
              device={device}
            />
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex justify-start flex-row items-center py-4">
        <div className="flex justfy-start flex-col items-start pr-8">
          <Text>Connector parameter</Text>
          <Text>{device?.connectorParameter ?? "-"}</Text>
        </div>
      </div>
      <Subheading>Digital Twin</Subheading>
      <Text>
        Beware that updating a parameter may have an impact on your real-world
        device.
      </Text>
      <Divider className="mb-2" />
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Parameter</TableHeader>
            <TableHeader>Current value</TableHeader>
            <TableHeader>Last change</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {device?.parameterArray.map((parameter) => {
            return (
              <TableRow key={parameter.name}>
                <TableCell>
                  <Text>{parameter.name}</Text>
                </TableCell>
                <TableCell className="flex justify-between flex-row">
                  <Field>
                    <ParameterInput
                      parameter={parameter}
                      onChange={handleParameterOnChange}
                    />
                  </Field>
                  <Button onClick={() => handleParameterUpdate(parameter)}>
                    Update parameter
                  </Button>
                </TableCell>

                <TableCell>
                  <Timestamp timestamp={parameter.lastChangeAt} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Subheading className="mt-8">Map</Subheading>
      <Divider />
      <LoadScriptNext
        googleMapsApiKey="AIzaSyBhqDFMAGPGH-QyoTJL55RAobduqqdaPeI"
        preventGoogleFontsLoading={true}
        onLoad={() => {
          setMapLoaded(true);
        }}
        onUnmount={() => {
          setMapLoaded(false);
        }}
      >
        <GoogleMap
          mapContainerStyle={{
            height: "30vh",
            width: "100%",
            borderRadius: "15px",
            overflow: "hidden",
            marginTop: "10px",
          }}
          center={{
            lat: device?.location.coordinates[1] ?? 0,
            lng: device?.location.coordinates[0] ?? 0,
          }}
          zoom={10}
        >
          {mapLoaded === true && (
            <Marker
              position={{
                lat: device?.location.coordinates[1] ?? 0,
                lng: device?.location.coordinates[0] ?? 0,
              }}
            />
          )}
        </GoogleMap>
      </LoadScriptNext>

      <Subheading className="mt-8">Battery & Fuel</Subheading>
      <Divider />
      <DescriptionList>
        <DescriptionTerm>Range remaining</DescriptionTerm>
        <DescriptionDetails>
          {device?.rangeRemaining ?? "-.--"} km
        </DescriptionDetails>
        <DescriptionTerm>Fuel type</DescriptionTerm>
        <DescriptionDetails>
          {device?.fuel?.type.toUpperCase() ?? "-"}
        </DescriptionDetails>
        <DescriptionTerm>Fuel level</DescriptionTerm>
        <DescriptionDetails>
          {device?.fuel?.level ?? "-.--"}%
        </DescriptionDetails>
      </DescriptionList>

      <Subheading className="mt-8">Links</Subheading>
      <Divider />
      <DescriptionList>
        <DescriptionTerm>Device template</DescriptionTerm>
        <DescriptionDetails>
          <Button href={"./../../deviceTemplates/" + device?.deviceTemplateId}>
            Go to device template
          </Button>
        </DescriptionDetails>
        <DescriptionTerm>Connector</DescriptionTerm>
        <DescriptionDetails>
          <Button href={"./../../connectors/" + device?.connectorId}>
            Go to connector
          </Button>
        </DescriptionDetails>
      </DescriptionList>
    </>
  );
};

export default DeviceRoute;
