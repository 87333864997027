import { JUHUU } from "@juhuu/sdk-ts";
import { Badge } from "../../components/badge";

interface BadgeSimStatusProps {
  status: JUHUU.Sim.Object["status"] | null | undefined;
}

const BadgeSimStatus: React.FC<BadgeSimStatusProps> = ({ status }) => {
  switch (status as JUHUU.Sim.Object["status"]) {
    case "offline": {
      return <Badge color="zinc">Offline</Badge>;
    }

    case "attached": {
      return <Badge color="yellow">Attached</Badge>;
    }

    case "online": {
      return <Badge color="lime">Online</Badge>;
    }

    default: {
      return null;
    }
  }
};

export default BadgeSimStatus;
