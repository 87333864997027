import { useCallback, useEffect, useState } from "react";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Divider } from "../../components/divider";
import LocationType from "./LocationType";
import Utilization from "../../formatters/BadgeUtilization";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import { Avatar } from "../../components/avatar";
import { useProperty } from "../../context/PropertyContext";
import Address from "../../formatters/Address";
import OfferArray from "../../formatters/OfferArray";
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from "../../components/description-list";
import { Button } from "../../components/button";
import SessionArray from "../sessions/SessionArray";
import DeviceArray from "../devices/DeviceArray";

interface LocationRouteProps {}

const LocationRoute: React.FC<LocationRouteProps> = () => {
  const [location, setLocation] = useState<JUHUU.Location.Object | null>(null);
  const [locationArray, setLocationArray] = useState<JUHUU.Location.Object[]>(
    []
  );

  const [tariffArray, setTariffArray] = useState<JUHUU.Tariff.Object[]>([]);
  const { locationId } = useParams<{ locationId: string }>();
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [licenseTemplateArray, setLicenseTemplateArray] = useState<
    JUHUU.LicenseTemplate.Object[]
  >([]);

  const { property } = useProperty();

  const handleRefresh = useCallback(async () => {
    if (locationId === undefined) {
      return;
    }

    if (property === null) {
      return;
    }

    // fetch location and tariffArray
    const locationQueryPromise = juhuu.locations.retrieve(
      {
        locationId: locationId,
      },
      {
        expand: ["tariffArray"],
      }
    );

    // fetch location array
    const locationArrayPromise = juhuu.locations.list({
      propertyId: property.id,
      rentableDeviceGroupLocationId: locationId,
    });

    const [locationQuery, locationArrayQuery] = await Promise.all([
      locationQueryPromise,
      locationArrayPromise,
    ]);

    if (locationQuery.ok === true) {
      setLocation(locationQuery.data.location);
      setTariffArray(locationQuery.data.tariffArray);
    }

    if (locationArrayQuery.ok === true) {
      setLocationArray(locationArrayQuery.data);
    }

    // fetch licenses
    const licenseTemplateIdArray: string[] = [];

    if (locationQuery.data.location.rentOfferArray !== undefined) {
      locationQuery.data.location.rentOfferArray.forEach((offer) => {
        offer.licenseTemplateIdArray.forEach((licenseTemplateId) => {
          licenseTemplateIdArray.push(licenseTemplateId);
        });
      });
    }

    if (locationQuery.data.location.reservationOfferArray !== undefined) {
      locationQuery.data.location.reservationOfferArray.forEach((offer) => {
        offer.licenseTemplateIdArray.forEach((licenseTemplateId) => {
          licenseTemplateIdArray.push(licenseTemplateId);
        });
      });
    }

    // strip duplicates
    const uniqueLicenseTemplateIdArray = Array.from(
      new Set(licenseTemplateIdArray)
    );

    // fetch licenseTemplates
    const licenseTemplateQueryPromiseArray: Promise<
      JUHUU.HttpResponse<JUHUU.LicenseTemplate.Retrieve.Response>
    >[] = [];

    uniqueLicenseTemplateIdArray.forEach(async (licenseTemplateId) => {
      licenseTemplateQueryPromiseArray.push(
        juhuu.licenseTemplates.retrieve({
          licenseTemplateId: licenseTemplateId,
        })
      );
    });

    const licenseTemplateQueryArray = await Promise.all(
      licenseTemplateQueryPromiseArray
    );

    // evalueate licenseTemplateQueryArray
    const tempLicenseTemplateArray: JUHUU.LicenseTemplate.Object[] = [];

    licenseTemplateQueryArray.forEach((licenseTemplateQuery) => {
      if (licenseTemplateQuery.ok === true) {
        tempLicenseTemplateArray.push(
          licenseTemplateQuery.data.licenseTemplate
        );
      }
    });

    setLicenseTemplateArray(tempLicenseTemplateArray);
  }, [locationId, property]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
        <div className="flex gap-4 items-center">
          <Heading>{location?.name}</Heading>
          <Utilization location={location} />
        </div>

        <div className="flex items-center gap-4">
          <div className="flex items-center gap-1">
            <Text>{locationId}</Text>
          </div>

          {/* <Button
            href={
              "/properties/" + property?.id + "/sessions/" + sessionId + "/edit"
            }
          >
            Edit
          </Button> */}
        </div>
      </div>
      <Divider />
      <div className="flex justify-start flex-row items-center py-4">
        <div className="flex justfy-start flex-col items-start pr-8">
          <Text>Type</Text>
          <Text>
            <LocationType type={location?.type} />
          </Text>
        </div>
        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>Address</Text>
          <Text>
            <Address address={location?.address} />
          </Text>
        </div>
      </div>
      <Subheading className="mt-8">Map</Subheading>
      <Divider />
      <LoadScriptNext
        googleMapsApiKey="AIzaSyBhqDFMAGPGH-QyoTJL55RAobduqqdaPeI"
        preventGoogleFontsLoading={true}
        onLoad={() => {
          setMapLoaded(true);
        }}
        onUnmount={() => {
          setMapLoaded(false);
        }}
      >
        <GoogleMap
          mapContainerStyle={{
            height: "30vh",
            width: "100%",
            borderRadius: "15px",
            overflow: "hidden",
            marginTop: "10px",
          }}
          center={{
            lat: location?.location.coordinates[1] ?? 0,
            lng: location?.location.coordinates[0] ?? 0,
          }}
          zoom={10}
        >
          {mapLoaded === true && (
            <Marker
              position={{
                lat: location?.location.coordinates[1] ?? 0,
                lng: location?.location.coordinates[0] ?? 0,
              }}
            />
          )}
        </GoogleMap>
      </LoadScriptNext>
      <Subheading className="mt-8">Devices</Subheading>
      <Text>
        Devices at this location can be used after a user payed for their
        rental.
      </Text>
      <Divider />
      <DeviceArray
        deviceIdArray={
          location !== null
            ? location?.type === "rentableDeviceGroup"
              ? location.deviceIdArray
              : [location.deviceId]
            : []
        }
      />
      {(location?.type === "rentableDevice" ||
        location?.type === "rentableDeviceGroup") &&
        location.concurrentSessionIdArray.length > 0 && (
          <>
            <Subheading className="mt-8">
              Active rentals & reservations
            </Subheading>
            <Divider className="mb-2" />
            <SessionArray
              sessionListParams={{
                propertyId: property?.id,
                locationGroupId:
                  location.type === "rentableDeviceGroup"
                    ? location.id
                    : undefined,
                locationId:
                  location.type === "rentableDevice" ? location.id : undefined,
                statusArray: ["ready", "waitingForPayment"],
              }}
            />
          </>
        )}

      {location?.type === "rentableDeviceGroup" && (
        <>
          <Subheading className="mt-8">Locations</Subheading>
          <Text>
            The following list contains locations associated with this one.
          </Text>
          <Divider className="mb-2" />
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Utilization</TableHeader>
                <TableHeader>Type</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {locationArray.map((location) => {
                return (
                  <TableRow key={location.id} href={"./../" + location.id}>
                    {/*href={"./" + location.id}*/}
                    <TableCell className="font-medium flex flex-row justify-start items-center gap-4">
                      <Avatar src={location.iconLight} className="size-12" />
                      <Text>{location.name}</Text>
                    </TableCell>
                    <TableCell>
                      <Utilization location={location} />
                    </TableCell>
                    <TableCell>
                      <LocationType type={location.type} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
      {location?.reservationOfferArray !== undefined &&
        location.reservationOfferArray.length > 0 && (
          <>
            <Subheading className="mt-8">Reservation offers</Subheading>
            <Text>
              The following list contains offers users can choose from when
              creating a reservation. An offer always consits of one or more
              license and a tariff. If a user holds one or more of these
              licenses, they are allowed to create a reservation. In addtion,
              you may want to specify opening hours for that specific offer.
            </Text>
            <Divider className="mb-2" />
            <OfferArray
              offerArray={location?.reservationOfferArray}
              tariffArray={tariffArray}
              licenseTemplateArray={licenseTemplateArray}
            />
          </>
        )}

      {location?.rentOfferArray !== undefined &&
        location.rentOfferArray.length > 0 && (
          <>
            <Subheading className="mt-8">Rental offers</Subheading>
            <Text>
              The following list contains offers users can choose from when
              creating a rental. An offer always consits of one or more license
              and a tariff. If a user holds one or more of these licenses, they
              are allowed to create a rental. In addtion, you may want to
              specify opening hours for that specific offer.
            </Text>
            <Divider className="mb-2" />
            <OfferArray
              offerArray={location?.rentOfferArray}
              tariffArray={tariffArray}
              licenseTemplateArray={licenseTemplateArray}
            />
          </>
        )}
      <Subheading className="mt-8">Links</Subheading>
      <Divider />
      <DescriptionList>
        {(location?.type === "rentableDevice" ||
          location?.type === "rentableDeviceGroup") && (
          <>
            <DescriptionTerm>Accounting Area</DescriptionTerm>
            <DescriptionDetails>
              <Button
                href={"./../../accountingAreas/" + location?.accountingAreaId}
              >
                Go to accounting area
              </Button>
            </DescriptionDetails>
          </>
        )}

        <DescriptionTerm>Term</DescriptionTerm>
        <DescriptionDetails>
          <Button href={"./../../terms/" + location?.termId}>Go to term</Button>
        </DescriptionDetails>
      </DescriptionList>
      <Subheading className="mt-8">Completed sessions</Subheading>
      <Divider className="mb-2" />
      <SessionArray
        sessionListParams={{
          propertyId: property?.id,
          locationGroupId:
            location?.type === "rentableDeviceGroup" ? location.id : undefined,
          locationId:
            location?.type === "rentableDevice" ? location.id : undefined,
          statusArray: ["completed"],
        }}
      />
    </>
  );
};

export default LocationRoute;
/*
amountAuthorized: number; // amount of the location that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the location that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
