import { memo } from "react";
import { Handle, Position, NodeToolbar, NodeProps } from "@xyflow/react";
import { GraphNode } from "@juhuu/sdk-ts";
import { Text } from "../../components/text";
import NodeType from "./NodeType";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../components/dropdown";
import { ChevronDownIcon } from "@heroicons/react/16/solid";

const FlowStartNode: React.FC<{ node: GraphNode }> = ({ node }) => {
  if (node.type !== "flow.start") {
    return null;
  }

  return (
    <>
      <Dropdown>
        <DropdownButton outline>
          {node.trigger}
          <ChevronDownIcon />
        </DropdownButton>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              console.log("draft");
            }}
          >
            Draft
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              console.log("published");
            }}
          >
            Published
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Handle type="source" position={Position.Bottom} />
    </>
  );
};

const CommandExecuteNode: React.FC<{ node: GraphNode }> = ({ node }) => {
  if (node.type !== "command.execute") {
    return null;
  }

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </>
  );
};

const FlowIfNode: React.FC<{ node: GraphNode }> = ({ node }) => {
  if (node.type !== "flow.if") {
    return null;
  }

  return (
    <>
      <Text>Select a condition</Text>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Left} id="true" />
      <Handle type="source" position={Position.Right} id="false" />
    </>
  );
};

const FlowExceptionNode: React.FC<{ node: GraphNode }> = ({ node }) => {
  if (node.type !== "flow.exception") {
    return null;
  }

  return (
    <>
      <Handle type="target" position={Position.Top} />
    </>
  );
};

export type NodeFlowStartProps = {
  data: {
    node: GraphNode;
  };
} & NodeProps;

const NodeComponent: React.FC<NodeFlowStartProps> = ({ data }) => {
  return (
    <div className="bg-white p-2 border border-zinc-300 rounded-xl">
      <NodeToolbar position={Position.Right}>
        <button>delete</button>
        <button>copy</button>
        <button>expand</button>
      </NodeToolbar>
      <div className="flex flex-col justify-start items-center">
        <Text>
          <NodeType type={data?.node?.type} />
        </Text>

        {data?.node?.type === "flow.start" && (
          <FlowStartNode node={data.node} />
        )}
        {data?.node?.type === "flow.if" && <FlowIfNode node={data.node} />}
        {data?.node?.type === "flow.exception" && (
          <FlowExceptionNode node={data.node} />
        )}
        {data?.node?.type === "command.execute" && (
          <CommandExecuteNode node={data.node} />
        )}
      </div>
    </div>
  );
};

export default memo(NodeComponent);
