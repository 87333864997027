import React, { useMemo } from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Badge, BadgeProps } from "../components/badge";

interface UtilizationProps {
  location: JUHUU.Location.Object | null;
  visible?: boolean;
}

const Utilization: React.FC<UtilizationProps> = ({
  location,
  visible = true,
}) => {
  const color: BadgeProps["color"] = useMemo((): BadgeProps["color"] => {
    const errorColor = "zinc";
    const successColor = "lime";

    if (location === null) {
      return errorColor;
    }

    switch (location.type) {
      case "rentableDevice":
      case "rentableDeviceGroup": {
        if (
          location.concurrentSessionIdArray.length >=
          location.maximumConcurrentSessions * 0.1
        ) {
          return successColor;
        } else {
          return errorColor;
        }
      }

      default: {
        return errorColor;
      }
    }
  }, [location]);

  const text: string = useMemo(() => {
    if (location === null) {
      return "Error";
    }

    if (
      location.type !== "rentableDevice" &&
      location.type !== "rentableDeviceGroup"
    ) {
      return "";
    }

    return (
      location.concurrentSessionIdArray.length.toString() +
      " of " +
      location.maximumConcurrentSessions.toString()
    );
  }, [location]);

  if (visible === false || location === null) {
    return null;
  }

  return <Badge color={color}>{text}</Badge>;
};

export default Utilization;
