import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { Divider } from "../../components/divider";
import { CheckCircleIcon, ClockIcon } from "@heroicons/react/16/solid";
import { Text } from "../../components/text";
import { useMemo } from "react";
import Amount from "../../formatters/Amount";
import PaymentMethod from "../../formatters/PaymentMethod";
import Timestamp from "../../formatters/Timestamp";

interface TimelineProps {
  payment: JUHUU.Payment.Object | null | undefined;
}

const Timeline: React.FC<TimelineProps> = ({ payment }) => {
  const stepNumber = useMemo(() => {
    switch (payment?.status) {
      case "waitingForConfirmation": {
        return 0;
      }

      case "waitingForTransitConfirmation": {
        return 1;
      }

      case "waitingForAmountFinalization": {
        return 2;
      }

      case "waitingForCapture": {
        return 3;
      }

      case "inTransitToProvider": {
        return 4;
      }

      case "captured": {
        return 5;
      }

      case "inTransitToProperty": {
        return 6;
      }

      case "payedOut": {
        return 7;
      }

      default: {
        return 0;
      }
    }
  }, [payment]);
  return (
    <>
      <Subheading className="mt-8">Timeline</Subheading>
      <Divider />
      <div className="w-full flex flex-row justify-start items-center gap-4 my-4">
        {stepNumber === 0 && (
          <>
            <ClockIcon color="#cdcdd0" className="w-5" />
            <div>
              <Text>Waiting for user confirmation</Text>
            </div>
          </>
        )}

        {stepNumber > 0 && (
          <>
            <CheckCircleIcon color="#acf636" className="w-5" />
            <div>
              <Text>Confirmed by user</Text>
              <Text>
                The user accepted that{" "}
                <Amount
                  amount={payment?.amountAuthorized}
                  currencyCode={payment?.currencyCode}
                />{" "}
                is authorized on their payment method. The user used the
                following payment method:{" "}
                <PaymentMethod paymentMethod={payment?.paymentMethod} />
              </Text>
            </div>
          </>
        )}
      </div>
      <div className="w-full flex flex-row justify-start items-center gap-4 my-4">
        {stepNumber <= 1 && (
          <>
            <ClockIcon color="#cdcdd0" className="w-5" />
            <div>
              <Text>Waiting for transit confirmation</Text>
            </div>
          </>
        )}

        {stepNumber > 1 && (
          <>
            <CheckCircleIcon color="#acf636" className="w-5" />
            <div>
              <Text>
                Payment service provider confirmed that the funds were
                authorized
              </Text>
            </div>
          </>
        )}
      </div>
      <div className="w-full flex flex-row justify-start items-center gap-4 my-4">
        {stepNumber <= 2 && (
          <>
            <ClockIcon color="#cdcdd0" className="w-5" />
            <div>
              <Text>Waiting for amount finalization</Text>
              <Text>
                Once the final price for this payment was determined, the
                payment is finalized. This can have several reasons, for example
                a "Pay-as-you-go" session that was not yet terminated.
              </Text>
            </div>
          </>
        )}

        {stepNumber > 2 && (
          <>
            <CheckCircleIcon color="#acf636" className="w-5" />
            <div>
              <Text>Amount finalized</Text>
              <Text>
                An amount of{" "}
                <Amount
                  amount={payment?.amountFinal}
                  currencyCode={payment?.currencyCode}
                />{" "}
                for this payment was finalized.
              </Text>
            </div>
          </>
        )}
      </div>
      <div className="w-full flex flex-row justify-start items-center gap-4 my-4">
        {stepNumber <= 3 && (
          <>
            <ClockIcon color="#cdcdd0" className="w-5" />
            <div>
              <Text>Waiting for capture</Text>
              <Text>
                After the payment was finalized, the funds are captured.
              </Text>
            </div>
          </>
        )}

        {stepNumber > 3 && (
          <>
            <CheckCircleIcon color="#acf636" className="w-5" />
            <div>
              <Text>Captured</Text>
              <Text>
                An amount of{" "}
                <Amount
                  amount={payment?.amountCaptured}
                  currencyCode={payment?.currencyCode}
                />{" "}
                was captured from the user's payment method.
              </Text>
            </div>
          </>
        )}
      </div>
      <div className="w-full flex flex-row justify-start items-center gap-4 my-4">
        {stepNumber <= 4 && (
          <>
            <ClockIcon color="#cdcdd0" className="w-5" />
            <div>
              <Text>In transit to JUHUU's payment service provider</Text>
            </div>
          </>
        )}

        {stepNumber > 4 && (
          <>
            <CheckCircleIcon color="#acf636" className="w-5" />
            <div>
              <Text>In transit to JUHUU's payment service provider</Text>
              <Text>
                The funds are on their way to JUHUU's payment service provider.
                This should take until{" "}
                <Timestamp timestamp={payment?.estimatedReadyForPayoutAt} />,
                but might take longer in rare cases.
              </Text>
            </div>
          </>
        )}
      </div>
      <div className="w-full flex flex-row justify-start items-center gap-4 my-4">
        {stepNumber <= 5 && (
          <>
            <ClockIcon color="#cdcdd0" className="w-5" />
            <div>
              <Text>Waiting for payout</Text>
              <Text>
                A transfer to your bank account has not yet been initiated.
              </Text>
            </div>
          </>
        )}

        {stepNumber > 5 && (
          <>
            <CheckCircleIcon color="#acf636" className="w-5" />
            <div>
              <Text>Payout initiated</Text>
              <Text>A transfer to your bank account has been initiated.</Text>
            </div>
          </>
        )}
      </div>
      <div className="w-full flex flex-row justify-start items-center gap-4 my-4">
        {stepNumber <= 6 && (
          <>
            <ClockIcon color="#cdcdd0" className="w-5" />
            <div>
              <Text>Waiting to be transfered to your bank</Text>
            </div>
          </>
        )}

        {stepNumber > 6 && (
          <>
            <CheckCircleIcon color="#acf636" className="w-5" />
            <div>
              <Text>Funds are on their way!</Text>
              <Text>
                An amount of{" "}
                <Amount
                  amount={payment?.amountToPayout}
                  currencyCode={payment?.currencyCode}
                />{" "}
                is on its way to your bank account.
              </Text>
            </div>
          </>
        )}
      </div>
      <div className="w-full flex flex-row justify-start items-center gap-4 my-4">
        {stepNumber < 7 && (
          <>
            <ClockIcon color="#cdcdd0" className="w-5" />
            <div>
              <Text>Waiting for arrival at your bank.</Text>
            </div>
          </>
        )}

        {stepNumber === 7 && (
          <>
            <CheckCircleIcon color="#acf636" className="w-5" />
            <div>
              <Text>Payed out</Text>
              <Text>
                An amount of{" "}
                <Amount
                  amount={payment?.amountToPayout}
                  currencyCode={payment?.currencyCode}
                />{" "}
                was successfully transfered to your bank account. This payment
                is now completed.
              </Text>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Timeline;
