import { GraphNode } from "@juhuu/sdk-ts";

interface NodeTypeProps {
  type: GraphNode["type"];
}

const NodeType: React.FC<NodeTypeProps> = ({ type }) => {
  switch (type) {
    case "flow.start": {
      return <>Start</>;
    }

    case "command.execute": {
      return <>Execute Command</>;
    }

    case "device.message": {
      return <>Send message to device</>;
    }

    case "flow.end": {
      return <>End</>;
    }

    case "flow.exception": {
      return <>Throw an error</>;
    }

    case "flow.if": {
      return <>If</>;
    }

    case "open.browser": {
      return <>Open browser</>;
    }

    case "open.phone": {
      return <>Call phone number</>;
    }

    case "parameter.set": {
      return <>Set parameter</>;
    }

    case "property.notify": {
      return <>Notify all property owners</>;
    }

    case "user.notify": {
      return <>Notify user</>;
    }

    default: {
      return <>Invalid node type</>;
    }
  }
};

export default NodeType;
