import React, { useCallback, useEffect, useMemo, useState } from "react";
import { juhuu } from "../../juhuuClass";
import { Heading, Subheading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { JUHUU } from "@juhuu/sdk-ts";
import { Divider } from "../../components/divider";
import { Table, TableBody, TableCell, TableRow } from "../../components/table";
import Amount from "../../formatters/Amount";
import Timestamp from "../../formatters/Timestamp";
import BadgeStatusPayment from "../payments/BadgeStatusPayment";
import BadgeSessionStatus from "../sessions/BadgeSessionStatus";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Text } from "../../components/text";

interface PropertyRouteProps {}

interface RevenueData {
  date: string;
  revenue: number;
}

interface RentalData {
  date: string;
  rentals: number;
}

const PropertyRoute: React.FC<PropertyRouteProps> = () => {
  const { property } = useProperty();

  const [paymentArray, setPaymentArray] = useState<JUHUU.Payment.Object[]>([]);
  const [sessionArray, setSessionArray] = useState<JUHUU.Session.Object[]>([]);
  const [revenueData, setRevenueData] = useState<RevenueData[]>([]);
  const [rentalData, setRentalData] = useState<RentalData[]>([]);
  const [rentalDataAxisTickCount, setRentalDataAxisTickCount] =
    useState<number>(6);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: property?.payoutCurrencyCode ?? "eur",
      }),
    [property]
  );

  const handleRefresh = useCallback(async () => {
    if (property?.id === undefined) {
      return;
    }
    const paymentQueryPromise = juhuu.payments.list({
      propertyId: property?.id,
      createdAt: {
        gte: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime(),
      },
    });

    const sessionQuery = await juhuu.sessions.list({
      propertyId: property?.id,
    });

    const paymentQuery = await paymentQueryPromise;

    if (sessionQuery.ok === false || paymentQuery.ok === false) {
      return;
    }

    setSessionArray(sessionQuery.data);
    setPaymentArray(paymentQuery.data);

    // Process data for charts
    const revenueByDay: Record<string, number> = {};
    const rentalsByDay: Record<string, number> = {};
    let hightestRentalCount = 0;

    paymentQuery.data.forEach((payment) => {
      const date = new Date(payment.createdAt).toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
      if (revenueByDay[date] === undefined) {
        revenueByDay[date] = 0;
      }
      revenueByDay[date] +=
        (payment.amountToPayout ??
          payment.amountFinal ??
          payment.amountAuthorized) / 100;
    });

    sessionQuery.data.forEach((session) => {
      const date = new Date(session.createdAt).toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
      if (rentalsByDay[date] === undefined) {
        rentalsByDay[date] = 0;
      }
      rentalsByDay[date] += 1;
    });

    // adjust tickCount for rental data
    Object.values(rentalsByDay).forEach((rentals) => {
      if (rentals > hightestRentalCount) {
        hightestRentalCount = rentals + 3; // add three more ticks to make the diagram look better
      }
    });

    setRentalDataAxisTickCount(hightestRentalCount);

    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
    }).reverse();

    setRevenueData(
      last7Days.map((date) => ({
        date,
        revenue: revenueByDay[date] ?? 0,
      }))
    );

    setRentalData(
      last7Days.map((date) => ({
        date,
        rentals: rentalsByDay[date] ?? 0,
      }))
    );
  }, [property]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <Heading>{property?.name}</Heading>
      <Divider className="mb-4" />
      <div className="w-full flex flex-row mt-8 gap-4">
        <div className="w-1/2">
          <Subheading className="mb-4">
            New payments in the last 7 days
          </Subheading>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={revenueData} margin={{ left: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis tickFormatter={(value) => formatter.format(value)} />
              <Tooltip
                content={(props) => {
                  if (props.payload === undefined) {
                    return null;
                  }

                  if (props.payload.length === 0) {
                    return null;
                  }
                  return (
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                      <Text>
                        {formatter.format(props.payload[0].value as number)} in
                        payments on{" "}
                        {new Date(props.label).toLocaleDateString("en-US", {
                          month: "short",
                          day: "2-digit",
                        })}
                      </Text>
                    </div>
                  );
                }}
              />
              <Line
                type="monotone"
                dataKey="revenue"
                stroke="#6e17fa"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>

          <Table dense className="mt-8">
            <TableBody>
              {paymentArray.slice(0, 6).map((payment) => (
                <TableRow key={payment.id} href={"./payments/" + payment.id}>
                  <TableCell>
                    <Amount
                      amount={
                        payment.amountToPayout ??
                        payment.amountFinal ??
                        payment.amountAuthorized
                      }
                      currencyCode={payment.currencyCode}
                    />
                  </TableCell>
                  <TableCell>
                    <Timestamp timestamp={payment.createdAt} />
                  </TableCell>
                  <TableCell>
                    <BadgeStatusPayment status={payment.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="w-1/2">
          <Subheading className="mb-4">
            New rentals and reservations in the last 7 days
          </Subheading>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={rentalData} margin={{ left: 10 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                tickCount={rentalDataAxisTickCount}
                allowDecimals={false}
              />
              <Line
                type="monotone"
                dataKey="rentals"
                stroke="#6e17fa"
                activeDot={{ r: 8 }}
              />
              <Tooltip
                content={(props) => {
                  if (props.payload === undefined) {
                    return null;
                  }

                  if (props.payload.length === 0) {
                    return null;
                  }
                  return (
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                      <Text>
                        {props.payload[0].value} rentals and reservations on{" "}
                        {new Date(props.label).toLocaleDateString("en-US", {
                          month: "short",
                          day: "2-digit",
                        })}
                      </Text>
                    </div>
                  );
                }}
              />
            </LineChart>
          </ResponsiveContainer>
          <Table dense className="mt-8">
            <TableBody>
              {sessionArray.slice(0, 6).map((session) => (
                <TableRow key={session.id} href={"./sessions/" + session.id}>
                  <TableCell>{session.tariff.name.en} </TableCell>
                  <TableCell>
                    <Timestamp timestamp={session.createdAt} />
                  </TableCell>
                  <TableCell>
                    <BadgeSessionStatus status={session.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default PropertyRoute;
