import { JUHUU } from "@juhuu/sdk-ts";
import { Badge } from "../../components/badge";

interface BadgeDeviceStatusProps {
  status: JUHUU.Device.Object["status"] | null | undefined;
}

const BadgeDeviceStatus: React.FC<BadgeDeviceStatusProps> = ({ status }) => {
  switch (status) {
    case "running": {
      return <Badge color="lime">Running</Badge>;
    }

    case "shutdown": {
      return <Badge color="zinc">Shutdown</Badge>;
    }

    case "sleeping": {
      return <Badge color="zinc">Sleeping</Badge>;
    }

    default: {
      return null;
    }
  }
};

export default BadgeDeviceStatus;
