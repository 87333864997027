import { JUHUU } from "@juhuu/sdk-ts";
import { Badge } from "../components/badge";

interface PaymentMethodProps {
  paymentMethod: JUHUU.Payment.Object["paymentMethod"] | null | undefined;
}

type PaymentMethod =
  | "card"
  | "stripe_account"
  | "klarna"
  | "bancontact"
  | "eps"
  | "giropay"
  | "ideal"
  | "p24"
  | "sofort"
  | "unknown";

const PaymentMethod: React.FC<PaymentMethodProps> = ({ paymentMethod }) => {
  switch (paymentMethod) {
    case "bancontact": {
      return <>Bancontact</>;
    }

    case "card": {
      return <>Card</>;
    }

    case "eps": {
      return <>EPS</>;
    }

    case "giropay": {
      return <>Giropay</>;
    }

    case "ideal": {
      return <>iDEAL</>;
    }

    case "klarna": {
      return <>Klarna</>;
    }

    case "p24": {
      return <>P24</>;
    }

    case "sofort": {
      return <>Sofort</>;
    }

    case "stripe_account": {
      return <>Stripe account</>;
    }

    case "unknown": {
      return <>Unknown</>;
    }

    default: {
      return null;
    }
  }
};

export default PaymentMethod;
